import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';


@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  public datatype:'array'|'string' = 'string';
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { 
    if(typeof(data) === 'object'){
      this.datatype = 'array';
    }else{
      this.datatype = 'string';
    }

  }

  ngOnInit() {
  }
}
